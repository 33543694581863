<template>
  <div class="score-box">
    <small>{{ props.title }}</small>
    <div class="position-score text-center">
      <p class="fs-1 m-0">{{ props.score }}</p>
      <small>de {{ props.total || '100' }}</small>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({title: String, score: Number, total: Number})
</script>

<style lang="scss" scoped>
.score-box {

  small {
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .position-score {
    background-color: var(--indigo-100);
    font-weight: bold;
    border-radius: 0.5rem;
    padding: 0.5rem;

    p {
      color: var(--indigo-400);
      line-height: 25px;
    }
  }

  @media(min-width: 768px) {
    .position-score {
      padding: 1rem 0.5rem;
    }
  }
}
</style>
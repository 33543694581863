<template>
  <div v-if="props.placeId" class="reviews">
    <Heading heading="Tus Reseñas"
      subheading="Este código QR le permitirá a tus clientes escribir una reseña de tu negocio en google." />
    <div class="mb-5" v-if="store.place?.name">
      <p class="m-0">Negocio: <a :href="bussinessLink()" target="_blank">{{ store.place.name }}</a></p>
      <p class="m-0">Direcci&oacute;n: {{ store.place.formatted_address }}</p>
    </div>
    <div class="personalization-container">
      <h4>Personaliza tu QR</h4>
      <Divider />
      <div class="personalization">
        <div class="d-flex justify-content-start align-items-start flex-column gap-2 mb-4" style="width: fit-content">
          <div class="m-auto" id="qr-code" ref="qrcode"></div>
          <Button class="w-100" label="DESCARGAR" icon="pi pi-download" iconPos="right" @click="downloadQRCode" autofocus
            style="width: 186px" />
          <Divider v-if="store.email"><span>O</span></Divider>
          <div v-if="store.email">
            <InputText class="w-100 mb-2" placeholder="email" disabled v-model="store.email" />
            <Button class="w-100" label="ENVIAR POR EMAIL" icon="pi pi-send" iconPos="right" @click="handleSendQRCode"
              style="width: 186px" :loading="sendEmailLoading" />
          </div>
        </div>
        <Accordion :activeIndex="0">
          <AccordionTab header="Logo">
            <p>Sube una imagen de tu logo y aparecer&aacute; en el centro de tu QR</p>
            <FileUpload mode="basic" chooseLabel="Elegir" accept="image/*" :maxFileSize="1000000" :auto="true"
              customUpload @uploader="onLogoUpload" />
            <Divider type="solid">
              <span>O</span>
            </Divider>
            <p>Si tienes una url, p&eacute;gala aqu&iacute;</p>
            <QRLogoInput @inputChange="onLogoInputChanged" />
          </AccordionTab>
          <AccordionTab header="Colores">
            <p class="m-0">Selecciona colores para que el QR sea m&aacute;s acorde a tu marca.</p>
            <small class="d-block mb-4" style="font-size: 0.75rem; color: gray;">Tip: Si ya tienes el c&oacute;digo HEX de
              tu color, puedes pegarlo directamente en el input!</small>
            <div class="d-flex justify-content-start align-items-center gap-4 flex-wrap">
              <QRColorPicker label="Principal" defaultValue="000000" @colorPicked="onDotsColorPicked" />
              <QRColorPicker label="Secundario" defaultValue="ffffff" @colorPicked="onBgColorPicked" />
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  </div>
  <div v-else-if="!props.placeId">
    <h4>Ups! &#x1f643;&#xfe0f;</h4>
    <p>No hemos podido encontrar tu ficha de Google. Int&eacute;ntalo m&aacute;s tarde.</p>
  </div>
  <div v-else>
    <h4>Ups! &#x1f643;&#xfe0f;</h4>
    <p>Falta un par&aacute;metro requerido.</p>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import QRCodeStyling from 'qr-code-styling'
import QRLogoInput from '@/components/qrLogoInput/QRLogoInput.vue'
import QRColorPicker from '@/components/qrColorPicker/QRColorPicker.vue'
import { useGlobalStore } from '@/stores';
import { blobToBase64 } from '@/helpers/helpers';
import services from '@/helpers/services';
import { useToast } from 'primevue/usetoast';

const store = useGlobalStore();
const toast = useToast();

const qrcode = ref();
const qrCodeStyling = ref();
const sendEmailLoading = ref(false);

const props = defineProps({
  placeId: String
})

const bussinessLink = () => {
  return `https://www.google.com/maps/place/?q=place_id:${props.placeId}`
}

const updateQR = (options) => {
  qrCodeStyling.value.update(options)
}

const generateQRCode = () => {
  const qrCodeOptions = {
    width: 300,
    height: 300,
    type: 'svg',
    data: `https://search.google.com/local/writereview?placeid=${props.placeId}`,
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 5,
    },
  };

  return new QRCodeStyling(qrCodeOptions);
}

const downloadQRCode = () => {
  qrCodeStyling.value.download({
    name: `Mi QR de Reseñas`,
    extension: "png"
  })

  try {
    if (window.pa) {
      window.pa.track({ name: 'Descarga QR', unit: props.placeId })
    }
  } catch (error) {
    console.err("Error al enviar click a phpnxlt");
  }
};

const handleSendQRCode = async () => {
  sendEmailLoading.value = true;
  try {
    const blobData = await qrCodeStyling.value.getRawData()
    const base64QRCode = await blobToBase64(blobData)
    const result = await services.putQRCodeToEmail({ base64QRCode, email: store.email })

    if (result.statusCode !== 200) {
      toast.add({ severity: "error", summary: "Error al enviar", detail: result.error })
      return;
    }

    toast.add({ severity: "success", summary: "QR enviado" })
  } catch (error) {
    toast.add({ severity: "error", summary: "Error al enviar", detail: error })
  }
  sendEmailLoading.value = false;

  try {
    if (window.pa) {
      window.pa.track({ name: 'Send QR', unit: props.placeId })
    }
  } catch (error) {
    console.err("Error al enviar click a phpnxlt");
  }
}

const onLogoUpload = (event) => {
  const logo = event.files[0];

  updateQR({ image: logo.objectURL })
};

const onLogoInputChanged = async ({ file }) => {
  if (!file) {
    updateQR({ image: "" })
    return;
  }

  const objectURL = URL.createObjectURL(file)

  updateQR({ image: objectURL })
}

const onDotsColorPicked = (value) => {
  updateQR({ dotsOptions: { color: `#${value}` } })
}

const onBgColorPicked = (value) => {
  updateQR({ backgroundOptions: { color: `#${value}` } })
}

onMounted(() => {
  if (props.placeId) {
    qrCodeStyling.value = generateQRCode();
    qrCodeStyling.value.append(qrcode.value)
  }
})
</script>

<style lang="scss" scoped>
.personalization-container {
  margin: auto;
}

.personalization {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 2rem;
}

button {
  border-radius: 0.5rem;
}

:deep(.p-accordion.p-component) {
  width: 100%;
}

@media(min-width: 768px) {

  .personalization {
    flex-direction: row;
  }

  :deep(.p-accordion.p-component) {
    max-width: 600px;
  }
}
</style>
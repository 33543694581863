import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

import "@/assets/app.css";

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import PrimeVue from 'primevue/config';
import InlineMessage from 'primevue/inlinemessage';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import TabMenu from 'primevue/tabmenu';
import BlockUI from 'primevue/blockui';
import Rating from 'primevue/rating';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
import ColorPicker from 'primevue/colorpicker';
import Divider from 'primevue/divider';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';


import { router } from "./router";

// pages
import App from './App.vue'
import Heading from './components/heading/Heading.vue'
import ScoresSummary from './components/scoresSummary/ScoresSummary.vue'
import ExtendedScoresSummary from './components/scoresSummary/ExtendedScoresSummary.vue'
import ScoreBox from './components/scoresSummary/ScoreBox.vue'
import AverageBar from './components/scoresSummary/AverageBar.vue'
import SummaryResult from './components/scoresSummary/SummaryResult.vue'

const pinia = createPinia()
const app = createApp(App)

app.use(pinia);
app.use(router);
app.use(PrimeVue);
app.use(ToastService);

app.component('Heading', Heading)
  .component('ScoresSummary', ScoresSummary)
  .component('ScoreBox', ScoreBox)
  .component('AverageBar', AverageBar)
  .component('SummaryResult', SummaryResult)
  .component('ExtendedScoresSummary', ExtendedScoresSummary)

app.component('InlineMessage', InlineMessage)
  .component('InputText', InputText)
  .component('Checkbox', Checkbox)
  .component('Button', Button)
  .component('Toast', Toast)
  .component('TabMenu', TabMenu)
  .component('BlockUI', BlockUI)
  .component('Rating', Rating)
  .component('Dialog', Dialog)
  .component('FileUpload', FileUpload)
  .component('ColorPicker', ColorPicker)
  .component('Divider', Divider)
  .component('Accordion', Accordion)
  .component('AccordionTab', AccordionTab)
  .component('IconField', IconField)
  .component('InputIcon', InputIcon)

app.mount('#app')
<template>
  <div id="map-container">
    <div id="autocomplete">
      <InputText id="autocomplete-input" class="w-100" v-model="autocompleteText" />
    </div>
    <div id="map"></div>
    <div id="infowindow-content">
      <h6 id="place-name"></h6>
      <p class="m-0" id="place-address"></p>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { Loader } from "@googlemaps/js-api-loader"
import { useGlobalStore } from '@/stores'
import { env } from '@/helpers/helpers'

const store = useGlobalStore();

const autocompleteText = ref('')

const loader = new Loader({
  apiKey: env.GMAPS_API_KEY,
  version: "weekly",
  libraries: ["places"]
});

onMounted(async () => {
  try {
    const { maps } = await loader.load();

    // renderizar el mapa
    const map = new maps.Map(document.getElementById("map"), {
      center: { lat: 40.4165, lng: -3.70256 },
      zoom: 7
    });

    // renderizar el autocomplete y enlazarlo al mapa
    const autocomplete = new maps.places.Autocomplete(document.getElementById('autocomplete-input'), {
      fields: ['place_id', 'geometry', 'formatted_address', 'name']
    })
    autocomplete.bindTo('bounds', map)

    // agregar ventana de informacion del lugar elegido en el mapa
    const infowindow = new maps.InfoWindow();
    const infowindowContent = document.getElementById("infowindow-content");
    infowindow.setContent(infowindowContent);

    // agregar el marcador del lugar elegido en el mapa y abrir ventana de informacion
    const marker = new maps.Marker({ map });
    marker.addListener("click", () => { infowindow.open(map, marker) });


    // cuando cambie el lugar elegido hacer lo de arriba de nuevo
    // obtener el place_id en una variable
    autocomplete.addListener("place_changed", () => {
      infowindow.close();

      const place = autocomplete.getPlace();
      // guardar el placeIf en el store
      // permite pasar a la siguiente pagina
      store.setPlaceId(place.place_id)
      // guardar todo el place para usarlo mas delante
      store.setPlace(place)

      if (!place.geometry || !place.geometry.location) {
        return;
      }

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      marker.setPlace({
        placeId: place.place_id,
        location: place.geometry.location,
      });

      marker.setVisible(true);

      infowindowContent.children.namedItem("place-name").textContent = place.name;
      infowindowContent.children.namedItem("place-address").textContent = place.formatted_address;
      infowindow.open(map, marker);
    });

    // al cerar el infowindow con un click
    infowindow.addListener('closeclick', () => {
      marker.setVisible(false)
      autocompleteText.value = ''
      store.setPlaceId('')
    })

  } catch (error) {
    console.log('error', error)
  }
})
</script>

<style lang="scss" scoped>
#map-container {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 0.5rem;
  border: 1px solid var(--bluegray-100);
  overflow: hidden;

  .p-inputtext {
    border-radius: 0;
    border: none;
    border: none;
  }

  #map {
    height: 400px;
  }
}

@media (min-width: 768px) {
  #map-container {
    grid-template-columns: 40% 1fr;

    .p-inputtext {
      border-bottom: 1px solid var(--bluegray-100);
    }
  }
}
</style>
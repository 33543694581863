<template>
  <div>
    <Heading heading="Genera tu QR de Reseñas" subheading="Toma ventaja ante tu competencia con este generador de QR para dirigir a tus clientes al apartado de reseñas de tu ficha de Google.
      Incrementarás sustancialmente el número de reseñas y, con ello, la visibilidad de tu negocio en internet." />
    <div class="mb-2">
      <h4>Localiza tu negocio</h4>
      <InlineMessage class="inline-message" severity="info">La localizaci&oacute;n de tu negocio requiere un Google
        Bussiness Profile
        v&aacute;lido para funcionar</InlineMessage>
    </div>
  </div>
  <div class="mb-2">
    <HomeMap />
  </div>
  <div class="email-field mb-2 mt-3">
    <label for="email">
      <h4>Email * </h4>
    </label>
    <div class="d-flex align-items-baseline gap-2">
      <InputText id="email" v-model="email" placeholder="Tu email" type="email" @input="validateEmail"
        :class="{ 'p-invalid': !validEmail }" />
      <small v-if="!validEmail" class="error-message">Ingrese un email v&aacute;lido</small>
    </div>
  </div>
  <div class="mb-2">
    <div class="checkbox-field mb-2">
      <Checkbox id="termsAgreement" v-model="termsAgreement" :binary="true" />
      <a class="checkbox-label" href="https://guotech.io/terminos-y-condiciones/" target="_blank">T&eacute;rminos y
        condiciones
        *</a>
    </div>
    <div class="checkbox-field mb-2">
      <Checkbox id="privacyPolicyAgreement" v-model="privacyPolicyAgreement" :binary="true" />
      <a class="checkbox-label" href="https://guotech.io/politica-de-privacidad/" target="_blank">Pol&iacute;tica de
        Privacidad *</a>
    </div>
  </div>
  <div class="button-container">
    <Button label="Generar QR" :icon="store.loading ? 'pi pi-spin pi-spinner' : 'pi pi-angle-right'" iconPos="right"
      :disabled="!fieldsValid || store.loading" @click="handleSubmit" />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast';
import { useGlobalStore } from '@/stores'
import HomeMap from '@/components/maps/HomeMap.vue'
import services from '@/helpers/services';
import { isProduction } from '@/helpers/helpers';

const router = useRouter()
const toast = useToast();
const store = useGlobalStore();

const email = ref("")
const validEmail = ref(true)
const termsAgreement = ref(false)
const privacyPolicyAgreement = ref(false)
const fieldsValid = computed(() => email.value && validEmail.value && termsAgreement.value && privacyPolicyAgreement.value && store.placeId)

const regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const validateEmail = () => validEmail.value = regexEmail.test(email.value);

const handleSubmit = async () => {
  if (!fieldsValid.value) return;
  // set loading
  store.setLoading(true);
  // generate sessionID
  const sessionID = crypto.randomUUID();

  // send data to backend
  const result = await services.postData({ sessionID, placeId: store.placeId, email: email.value })
  if (result.error) {
    store.setLoading(false);
    toast.add({ severity: 'error', summary: 'Error', detail: result.error, life: 3000 });
    return;
  }

  if (isProduction) {
    // send lead to hubspost
    const hubpostResult = await services.postUserToHubspot({ email: email.value })
    if (hubpostResult.error) {
      toast.add({ severity: 'error', summary: 'Error enviando a Hubspot', detail: hubpostResult.error, life: 3000 });
    }
  }

  // put data in store and navigate to next page
  store.setSessionData({ sessionID, email: email.value });

  // send request to get rest of data
  const data = await services.getReportData()

  store.setReportData(data);
  store.setLoading(false);

  //navigate to next page
  router.push({
    name: 'reviews',
    query: { gplaceid: store.placeId }
  });
}

</script>

<style lang="scss" scoped>
.p-inline-message :deep(span) {
  font-size: 0.7rem;
}

.email-field {
  display: flex;
  flex-direction: column;

  input {
    width: 300px;
  }
}

.checkbox-field {
  .checkbox-label {
    text-decoration: none;
    font-weight: 500;
    margin-left: 0.5rem;
  }
}

.button-container {
  text-align: end;

  button {
    border-radius: 1.5rem;
  }
}

@media (min-width: 768px) {
  .p-inline-message :deep(span) {
    font-size: 0.8rem;
  }
}
</style>
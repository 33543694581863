<template>
  <div class="menu">
    <router-link class="mobile-only text-center" to="/">
      <img src="@/assets/logo.svg" width="80px" />
    </router-link>
    <div class="separator mobile-only"></div>
    <div class="collapse-button mobile-only" @click="toggleMenu">
      <div class="d-flex align-items-center justify-content-start gap-1">
        <i class="fs-4 pi" :class="menuExpanded ? 'pi-angle-left' : 'pi-angle-right'" />
        <span v-if="menuExpanded">Colapsar</span>
      </div>
    </div>
    <div class="separator mobile-only"></div>
    <div class="menu-items">
      <MenuItem v-for="item in menuItems" v-bind:key="item.label" v-bind="item" :expanded="menuExpanded" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import MenuItem from './MenuItem.vue';
import { useRouter } from 'vue-router';
import { useGlobalStore } from '@/stores'

const router = useRouter();
const store = useGlobalStore();

const menuExpanded = ref(true);
const toggleMenu = () => menuExpanded.value = !menuExpanded.value

const menuItems = {
  /* home: {
    label: 'Inicio',
    icon: 'pi-home',
    handler() {
      router.push({ name: "home" })
    }
  },
  competitors: {
    label: 'Competencia',
    icon: 'pi-map',
    handler() {
      router.push({ name: "competitors" })
    }
  }, */
  reviews: {
    label: 'Reseñas',
    icon: 'pi-star',
    handler() {
      router.push({ name: 'reviews', query: { gplaceid: store.placeId } })
    }
  },
  search: {
    label: 'Buscar',
    icon: 'pi-search',
    handler() {
      router.push({ name: "search" })
    }
  }
}

</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  flex-direction: row;
  background: white;
  border-top: 1px solid var(--bluegray-100);
  z-index: 10;

  .separator {
    width: 100%;
    border: 1px solid var(--bluegray-100);
    margin: 2rem 0;
  }

  .collapse-button {
    cursor: pointer;
    color: var(--bluegray-300);
    transition: all 0.3s ease;

    :hover {
      color: var(--bluegray-500);
    }

    div {
      padding-left: 2rem;
    }

    span {
      margin: 0;
      font-weight: 500;
    }
  }

  .menu-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
  }
}

.mobile-only {
  display: none;
}

@media (min-width: 768px) {
  .menu {
    display: block;
    height: 100vh;
    padding: 5rem 1rem;
    z-index: auto;

    .menu-items {
      flex-direction: column;
      width: auto;
      gap: 0;
    }
  }

  .mobile-only {
    display: block;
  }
}
</style>
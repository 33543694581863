<template>
  <div class="heading mb-2">
    <h1 v-if="props.heading">{{ props.heading }}</h1>
    <p v-if="props.subheading">{{ props.subheading }}</p>
  </div>
</template>

<script setup>
const props = defineProps({ heading: String, subheading: String })
</script>

<style lang="scss" scoped>
.heading p {
  font-size: 1rem;
}

@media (min-width: 768px) {
  .heading p {
    font-size: 1.1rem;
  }

  h1 {
    font-size: 2rem;
  }

}
</style>
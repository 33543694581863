<template>
  <Dialog v-model:visible="store.premiumDialogOpen" modal header="Header" :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :draggable="false">
    <template #header>
      <h2>Compra An&aacute;lisis de Competencia Premium ✨</h2>
    </template>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
      aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
      sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
    <ul>
      <li>Lorem ipsum dolor sit amet</li>
      <li>Lorem ipsum dolor sit amet</li>
      <li>Lorem ipsum dolor sit amet</li>
      <li>Lorem ipsum dolor sit amet</li>
    </ul>
    <template #footer>
      <div class="d-flex justify-content-end gap-4">
        <Button label="Cancelar" @click="store.togglePremiumDialog(false)" severity="secondary" />
        <Button label="Comprar premium" icon="pi pi-dollar" iconPos="right" @click="store.togglePremiumDialog(false)"
          autofocus severity="help" />
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { useGlobalStore } from '@/stores'
const store = useGlobalStore();
</script>

<style lang="scss" scoped>
button {
  border-radius: 0.5rem;
}
</style>
import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      loading: false,
      premiumDialogOpen: false,

      // app data
      sessionID: "",
      placeId: "",
      place: {},
      email: "",
      reportData: {},
      selfBusiness: {}
    }
  },
  actions: {
    setLoading(loading) {
      this.loading = loading;
    },
    setPlaceId(newPlaceId) {
      this.placeId = newPlaceId
    },
    setPlace(newPlace) {
      this.place = newPlace
    },
    setSessionData({ sessionID, email }) {
      this.sessionID = sessionID;
      this.email = email
    },
    setReportData(newReportData) {
      this.reportData = newReportData;

      if (newReportData?.rankingCompetitors && newReportData?.rankingCompetitors.length) {
        this.selfBusiness = newReportData.rankingCompetitors.find((rc) => rc.self);
      }
    },
    togglePremiumDialog(premiumDialogOpen) {
      this.premiumDialogOpen = premiumDialogOpen
    }
  }
})

<template>
  <div v-if="props.rating && summaryData" class="summary-result" :class="summaryData?.class">
    <div class="summary-text">
      <i class="pi" :class="summaryData?.icon"></i>
      <p class="m-0">{{ summaryData?.keyword }} {{ props.rating }} de cada 10 negocios tienen mejor resultado
        que t&uacute;</p>
    </div>
    <button @click="store.togglePremiumDialog(true)">Contacta con nosotros</button>
  </div>
  <slot v-else></slot>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useGlobalStore } from '@/stores';

const props = defineProps({ rating: Number })
const store = useGlobalStore()
const summaryData = ref();

const ratingsEvaluation = (rating) => {
  if (rating > 1 || rating <= 3) {
    return 'good'
  } else if (rating > 3 || rating <= 6) {
    return 'regultar'
  } else {
    return 'bad'
  }
}

const summaryDataValues = {
  good: {
    keyword: "Genial!",
    class: 'green',
    icon: "pi-thumbs-up-fill",
  },
  regular: {
    keyword: "Regular.",
    class: 'yellow',
    icon: "pi-thumbs-down",
  },
  bad: {
    keyword: "Mal.",
    class: 'red',
    icon: "pi-thumbs-down-fill",
  },
}

onMounted(() => {
  if (!props.rating) {
    return;
  }
  const evaluation = ratingsEvaluation(props.rating);
  summaryData.value = summaryDataValues[evaluation];
})
</script>

<style lang="scss" scoped>
.summary-result {
  border-left-width: 1rem;
  border-left-style: solid;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  gap: 1rem;

  &.green {
    background-color: var(--green-100);
    border-color: var(--green-500);
    color: var(--green-500);
  }

  &.yellow {
    background-color: var(--yellow-100);
    border-color: var(--yellow-500);
    color: var(--yellow-500);
  }

  &.red {
    background-color: var(--red-100);
    border-color: var(--red-500);
    color: var(--red-500);
  }

  .summary-text {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    i {
      font-size: 1.5rem;
    }

    p {
      font-size: 0.8rem;
    }
  }

  button {
    border-radius: 2rem;
    color: white;
    background-color: var(--indigo-400);
    padding: 0.8rem 1.5rem;
    border: none;
    transition: all 0.3s ease;

    &:hover,
    &:active,
    &:focus {
      background-color: var(--indigo-500);
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;

    .summary-text {
      align-items: center;

      i {
        font-size: 1.2rem;
      }

      p {
        font-size: 1rem;
      }
    }

    button {
      margin-left: auto;
    }
  }
}
</style>
<template>
  <BlockUI :blocked="store.loading" fullScreen />
  <div class="layout" :class="mode">
    <Toast />
    <Menu class="mn"></Menu>
    <PremiumDialog />
    <div class="app">
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import { watch, ref } from 'vue'
import { useRoute } from 'vue-router'
import Menu from '@/components/menu/Menu.vue'
import PremiumDialog from '@/components/dialogs/PremiumDialog.vue'
import { useGlobalStore } from '@/stores'

const store = useGlobalStore()
const route = useRoute();

const mode = ref("");

watch(() => route.query.mode, () => {
  mode.value = route.query.mode
})

</script>

<style lang="scss" scoped>
.layout {
  display: grid;
  grid-template-areas: "app" "menu";
  position: relative;

  // if the query param mode=minimal appears in the url
  &.minimal {
    .mn {
      display: none;
    }

    .app {
      margin: 0;
      padding: 0;
      border-radius: 0;
      box-shadow: none;

      .reviews {
        :deep(.heading) {
          display: none;
        }

        :deep(.personalization-container) {

          h4,
          .p-divider {
            display: none;
          }
        }
      }
    }
  }

  .app {
    grid-area: app;
    padding: 1.5rem;
    padding-bottom: 8rem;
  }

  .mn {
    grid-area: menu;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .layout {
    grid-template-areas: "menu app";
    grid-template-columns: auto 1fr;

    .app {
      margin: 2rem 2rem 1rem 0rem;
      padding: 2rem 10rem 2rem 5rem;
      border-radius: 1rem;
      box-shadow: 0 0 1rem var(--bluegray-100);
    }

    .mn {
      position: initial;
      inset: unset;
    }
  }
}
</style>

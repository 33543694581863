<template>
  <div class="scores-summary d-flex flex-column justify-content-between" v-if="props.averageBarCurrent">
    <div class="scores-summary-upper">
      <div class="d-flex justify-content-between align-items-baseline mb-2">
        <h5>{{ props.title }}</h5>
        <Button class="p-0" label="ver detalles" link icon="pi pi-angle-right" iconPos="right" />
      </div>
      <div class="scores-container">
        <ScoreBox :title="props.scoreBoxTitle" :score="props.scoreBoxScore" />
        <AverageBar :title="props.averageBarTitle" :current="props.averageBarCurrent" :average="props.averageBarAverage"
          :subtext="props.averageBarSubtext" />
      </div>
    </div>
    <SummaryResult :rating="props.summaryResultRating" />
  </div>
</template>

<script setup>
const props = defineProps({
  title: String,
  scoreBoxTitle: String,
  scoreBoxScore: Number,
  averageBarTitle: String,
  averageBarSubtext: String,
  averageBarCurrent: Number,
  averageBarAverage: Number,
  summaryResultRating: Number,
})
</script>

<style lang="scss" scoped>
.scores-summary {
  $card-radius: 0.5rem;
  border: 1px solid var(--bluegray-100);
  border-radius: $card-radius;

  .scores-summary-upper {
    padding: 1rem 1rem 2rem 1rem;

    small {
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: bold;
    }

    h5,
    button {
      font-size: 1rem;
    }
  }


  .scores-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1rem;
  }

  @media (min-width: 768px) {
    .scores-summary-upper {
      h5 {
        font-size: 1.25rem;
      }
    }

    .scores-container {
      flex-direction: row;
      gap: 5rem;
    }
  }
}
</style>
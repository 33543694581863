export const env = {
  GMAPS_API_KEY: window.config.GMAK,
  BACKEND_API_URL: window.config.BU
}

export const isLocalhost = location.href.includes('localhost');
export const isProduction = !isLocalhost && !location.href.includes('dev.')

export const hometTabMenus = [
  { label: 'En mi zona' },
  { label: 'En mi ciudad' },
  { label: 'En mi comunidad autónoma' },
]

export const debounce = (func, wait, immediate) => {
  let timeout
  return function (...args) {
    return new Promise((resolve) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        timeout = null
        if (!immediate) {
          Promise.resolve(func.apply(this, [...args])).then(resolve)
        }
      }, wait)
      if (immediate && !timeout) {
        Promise.resolve(func.apply(this, [...args])).then(resolve)
      }
    })
  }
}

export const loadImageFromUrl = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);

    // check response is valid
    if (response.status !== 200) {
      throw new Error(response.status);
    }

    // check response is an image
    const contentType = response.headers.get('content-type');

    if (!contentType || !contentType.startsWith('image/')) {
      throw new Error("Response was not an image")
    }

    // convert image into Blob and then a File
    const blob = await response.blob();
    const file = new File([blob], 'qr-logo');

    return { file }
  } catch (error) {
    return { error }
  }
};

export const blobToBase64 = blob => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise(resolve => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

<template>
  <IconField>
    <InputIcon class="pi" :class="[loading ? 'pi-spin pi-spinner' : '']"> </InputIcon>
    <InputText class="w-100" placeholder="URL de la imagen" type="url" v-model="url" :invalid="isInvalid" />
    <div v-if="isInvalid">
      <small>URL no v&aacute;lida o error al cargar imagen</small>
    </div>
  </IconField>
</template>

<script setup>
import { ref, watch } from 'vue'
import { debounce, loadImageFromUrl } from '@/helpers/helpers';

const emit = defineEmits(['inputChange'])

const url = ref("");
const loading = ref(false);
const isInvalid = ref(false);

const debounced = debounce(async (url) => {
  const result = await loadImageFromUrl(url);
  return result
}, 1000)

watch(url, async () => {
  if (!url.value) {
    isInvalid.value = false;
    emit('inputChange', { file: "" })
    return;
  }

  loading.value = true
  const { file, error } = await debounced(url.value)
  loading.value = false
  isInvalid.value = !!error

  emit('inputChange', { file })
})
</script>

<style lang="scss" scoped>
small {
  color: var(--red-500);
}
</style>
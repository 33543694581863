<template>
  <div>
    <p class="m-0 fw-medium">{{ props.label }}</p>
    <div class="d-flex gap-1">
      <ColorPicker format="hex" v-model="color" @update:modelValue="onColorPicked" />
      <InputText type="text" v-model="color" @update:modelValue="onColorPicked" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  label: String,
  defaultValue: String
})

const emit = defineEmits(['colorPicked'])

const color = ref(props.defaultValue);

const onColorPicked = (value) => {
  if (!value) {
    color.value = props.defaultValue
    value = color.value;
  }
  emit('colorPicked', value)
}

</script>

<style lang="scss" scoped>
:deep(.p-colorpicker-preview) {
  width: 3rem;
  height: 3rem;
}

:deep(.p-inputtext.p-component) {
  width: 7rem;
}
</style>
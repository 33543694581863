<template>
  <div class="average-bar-container">
    <small v-if="props.title">{{ props.title }}</small>
    <div class="average-bar">
      <div class="current" :style="{ width: `${props.current}%` }">
        <small>{{ props.current }}</small>
      </div>
      <div class="average" :style="{ width: `${props.average}%` }">
        <div class="average-divider"></div>
        <small>{{ props.average }}</small>
      </div>
      <small>{{ props.total || 100 }}</small>
    </div>
    <p v-if="props.subtext">{{ props.subtext }} <span class="fw-bold">{{ props.average }}</span></p>
  </div>
</template>

<script setup>
const props = defineProps({ title: String, current: Number, average: Number, total: Number, subtext: String })
</script>

<style lang="scss" scoped>
.average-bar-container {

  small {
    font-size: 0.7rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .average-bar {
    background-color: var(--bluegray-50);
    border-radius: 1rem;
    position: relative;
    text-align: end;
    padding: 0 0.5rem 0 0;
    margin: 0.2rem 0;

    .current {
      background-color: var(--indigo-100);
      border-radius: 1rem;
      position: absolute;
      transition: all 0.2s ease;
      padding: 0 0.5rem 0 0;
    }

    .average {
      position: absolute;
      text-align: end;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 0.3rem;

      .average-divider {
        position: relative;
        top: -0.25rem;
        height: 2rem;
        width: 2px;
        border-left: 1px dashed black;
      }

      small {
        position: relative;
        top: -0.1rem;
      }
    }
  }
}
</style>
import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "./components/pages/LandingPage.vue";
/* import HomePage from "./components/pages/HomePage.vue";
import CompetitorsPage from "./components/pages/CompetitorsPage.vue"; */
import ReviewsPage from "./components/pages/ReviewsPage.vue";
import { useGlobalStore } from "./stores";

const dataGuard = (to) => {
  const store = useGlobalStore();

  // si el placeid viene por la url
  // e.g: reviews?gplaceid=ChIJa0v4WxvzQA0RSq2
  if (to.query.gplaceid) {
    store.setPlaceId(to.query.gplaceid);
  }

  // para pasar a la siguiente ruta (cualquiera menos "/") debe existir store.placeId al menos
  const hasDataEntered = store.placeId
  if (!hasDataEntered || to.name === 'landing') {
    return { name: 'landing' }
  }
}

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { name: 'landing', path: "/", component: LandingPage },
    /* { name: 'home', path: "/home", component: HomePage, beforeEnter: dataGuard },
    { name: 'competitors', path: "/competitors", component: CompetitorsPage, beforeEnter: dataGuard }, */
    {
      name: 'reviews', path: "/reviews", component: ReviewsPage,
      props: route => ({ placeId: route.query.gplaceid })
    },
    { name: 'search', path: "/search", component: LandingPage, beforeEnter: dataGuard },
    { name: 'notFound', path: '/:pathMatch(.*)*', component: LandingPage },
  ],
});

<template>
  <div class="menu-item" :class="[{ active: active }]">
    <span class="menu-link d-flex align-items-center justify-content-start gap-2" @click="props.handler">
      <i :class="props.icon" class="fs-4 pi" />
      <span v-if="props.expanded">{{ props.label }}</span>
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  icon: String,
  label: String,
  expanded: Boolean,
  handler: Function
})

const route = useRoute()

const active = computed(() => route.path === props.path)
</script>

<style lang="scss" scoped>
.menu-item {
  cursor: pointer;
  color: var(--bluegray-300);
  transition: all 0.3s ease;
  padding: 1rem;

  &:hover {
    color: var(--bluegray-500);
  }

  &.active {
    color: var(--indigo-500);
  }

  .menu-link {
    color: inherit;
    text-decoration: none;
  }

  :deep(span) {
    margin: 0;
    font-weight: 500;
    display: none;
  }

  @media (min-width: 768px) {
    width: 100%;
    padding: 0;

    .menu-link {
      padding: 1.5rem 2rem;
    }

    :deep(span) {
      display: block;
    }
  }
}
</style>
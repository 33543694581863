import { env } from "./helpers";

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

const postData = async ({ sessionID, email, placeId }) => {
  try {
    const datos_json = { 'email': email, 'placeId': placeId }

    const response = await fetch(`${env.BACKEND_API_URL}/competitors/sessions`, {
      method: 'post',
      body: JSON.stringify({ 'session_id': sessionID, 'datos_json': datos_json }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const result = await response.json()

    if (response.status !== 200) {
      return { error: result.detail }
    }
    else {
      return { data: response.ok }
    }

  } catch (error) {
    return { error }
  }
}

const postUserToHubspot = async ({ email }) => {
  try {
    const response = await fetch(`https://api.hsforms.com/submissions/v3/integration/submit/25526359/09063ff6-d40e-4357-bbb9-e8cf635f32c7`, {
      method: "post",
      body: JSON.stringify({
        fields: [
          {
            objectTypeId: "0-1",
            name: "email",
            value: email
          }],
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: "He leído y acepto la política de privacidad y cookies",
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: "He leído y acepto la política de privacidad y cookies"
              }
            ]
          }
        },
        context: {
          pageUri: window.location.href
        }
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const result = await response.json()
    if (response.status !== 200) {
      return { error: result.errors || result }
    }
    else {
      return { data: result }
    }
  } catch (error) {
    return { error }
  }
}

const getReportData = async () => {
  try {
    /*  const response = await fetch(env.BACKEND_API_URL, {
       method: 'post',
       body: JSON.stringify({ sessionID, email, placeId })
     })
 
     const result = await response.body.json()
 
     if (response.status !== 200) {
       return { error: result }
     }
 
     return result */
    await sleep(1000);
    return {
      rankingCompetitors: [ /* los datos del ranking de competencias */
        {
          name: "Padel Maya",
          placeId: "ChIJv6oP4N0-QQ0RJTloPWLX85Q",
          self: false,                                           /* Estará a true solo si es el negocio que se está analizando */
          formattedAddress: "C. Ochoa Ondategui, 21, 40001 Segovia, España",
          lat: 0.0,
          long: 0.0,
          distanceKms: 5.8,                               /* Distancia (en kms) a la ficha a analizar */
          rating: 4.5,
          userRatingCount: 3075,
          websiteUri: "https://guotech.io",
          primaryType: "spanish_restaurant",    /* En el front no hace falta por el momento */
          score: 85,                                            /* score entre 0 y 100 que determina la relevancia del negocio en las búsquedas */
          scorePosition: 1,                                 /* orden en el que se mostrará dentro del ranking de competencia */
          reviewscountPercentile: 59,              /* score entre 0 y 100 que determina los datos de cantidad de reseñas vs la competencia */
          reviewsratingPercentile: 59,             /* score entre 0 y 100 que determina los datos de valoración de reseñas vs la competencia */
          reviewspercentile: 59,                 /* score entre 0 y 100 que determina los datos de reseñas (cantidad y valoración) vs la competencia */
        },
        {
          name: "Padel Maya 2",
          placeId: "ChIJv6oP4N0-QQ0RJTloPWLX85Q",
          self: false,
          formattedAddress: "C. Ochoa Ondategui, 21, 40001 Segovia, España",
          lat: 0.0,
          long: 0.0,
          distanceKms: 5.8,
          rating: 4.2,
          userRatingCount: 30,
          websiteUri: "https://guotech.io",
          primaryType: "spanish_restaurant",
          score: 80,
          scorePosition: 2,
          reviewscountPercentile: 59,
          reviewsratingPercentile: 59,
          reviewspercentile: 59,
        },
        {
          name: "Padel Maya 3",
          placeId: "ChIJv6oP4N0-QQ0RJTloPWLX85Q",
          self: false,
          formattedAddress: "C. Ochoa Ondategui, 21, 40001 Segovia, España",
          lat: 0.0,
          long: 0.0,
          distanceKms: 5.8,
          rating: 4.2,
          userRatingCount: 30,
          websiteUri: "https://guotech.io",
          primaryType: "spanish_restaurant",
          score: 80,
          scorePosition: 3,
          reviewscountPercentile: 59,
          reviewsratingPercentile: 59,
          reviewspercentile: 59,
        },
        {
          name: "Padel Maya 4",
          placeId: "ChIJv6oP4N0-QQ0RJTloPWLX85Q",
          self: false,
          formattedAddress: "C. Ochoa Ondategui, 21, 40001 Segovia, España",
          lat: 0.0,
          long: 0.0,
          distanceKms: 5.8,
          rating: 4.0,
          userRatingCount: 30,
          websiteUri: "https://guotech.io",
          primaryType: "spanish_restaurant",
          score: 75,
          scorePosition: 4,
          reviewscountPercentile: 59,
          reviewsratingPercentile: 59,
          reviewspercentile: 59,
        },
        {
          name: "Padel Maya 5",
          placeId: "ChIJv6oP4N0-QQ0RJTloPWLX85Q",
          self: true,
          formattedAddress: "C. Ochoa Ondategui, 21, 40001 Segovia, España",
          lat: 0.0,
          long: 0.0,
          distanceKms: 5.8,
          rating: 4.0,
          userRatingCount: 30,
          websiteUri: "https://guotech.io",
          primaryType: "spanish_restaurant",
          score: 75,
          scorePosition: 5,
          reviewscountPercentile: 59,
          reviewsratingPercentile: 59,
          reviewspercentile: 59,
        },
        {
          name: "Padel Maya 7",
          placeId: "ChIJv6oP4N0-QQ0RJTloPWLX85Q",
          self: false,
          formattedAddress: "C. Ochoa Ondategui, 21, 40001 Segovia, España",
          lat: 0.0,
          long: 0.0,
          distanceKms: 5.8,
          rating: 3.9,
          userRatingCount: 30,
          websiteUri: "https://guotech.io",
          primaryType: "spanish_restaurant",
          score: 72,
          scorePosition: 6,
          reviewscountPercentile: 59,
          reviewsratingPercentile: 59,
          reviewspercentile: 59,
        },
        {
          name: "Padel Maya 8 self",
          placeId: "ChIJv6oP4N0-QQ0RJTloPWLX85Q",
          self: false,
          formattedAddress: "C. Ochoa Ondategui, 21, 40001 Segovia, España",
          lat: 0.0,
          long: 0.0,
          distanceKms: 5.8,
          rating: 3.9,
          userRatingCount: 30,
          websiteUri: "https://guotech.io",
          primaryType: "spanish_restaurant",
          score: 72,
          scorePosition: 7,
          reviewscountPercentile: 59,
          reviewsratingPercentile: 59,
          reviewspercentile: 59,
        },
        {
          name: "Padel Maya 9",
          placeId: "ChIJv6oP4N0-QQ0RJTloPWLX85Q",
          self: false,
          formattedAddress: "C. Ochoa Ondategui, 21, 40001 Segovia, España",
          lat: 0.0,
          long: 0.0,
          distanceKms: 5.8,
          rating: 3.9,
          userRatingCount: 30,
          websiteUri: "https://guotech.io",
          primaryType: "spanish_restaurant",
          score: 72,
          scorePosition: 8,
          reviewscountPercentile: 59,
          reviewsratingPercentile: 59,
          reviewspercentile: 59,
        },
      ],
      grid: [                /* los datos de los circulos del mapa */
        {
          lat: 0.0,
          long: 0.0,
          score: 54
        },
      ]
    }
  } catch (error) {
    return { error }
  }

}

const putQRCodeToEmail = async ({ base64QRCode, email }) => {
  try {
    const properties = {'freemium_qr': base64QRCode}
    const response = await fetch(`${env.BACKEND_API_URL}/contacts`, {
      method: 'put',
      body: JSON.stringify({'email': email, 'properties': properties}),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const result = await response.json()

    result['statusCode'] = response.status;
    return result;

  } catch (error) {
    return JSON.stringify({ "error": error, "statusCode": 500 });
  }

}

const services = { postData, getReportData, postUserToHubspot, putQRCodeToEmail: putQRCodeToEmail }

export default services;
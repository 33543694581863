<template>
  <div class="scores-summary">
    <div class="scores-summary-upper">
      <div class="d-flex justify-content-between align-items-baseline mb-2">
        <h5>{{ props.title }}</h5>
        <Button class="p-0" label="ver detalles" link icon="pi pi-angle-right" iconPos="right" />
      </div>
      <div class="scores-container">
        <ScoreBox :title="props.scoreBoxTitle" :score="props.scoreBoxScore" />
        <div>
          <AverageBar :title="props.firstAverageBarTitle" :current="props.firstAverageBarCurrent"
            :average="props.firstAverageBarAverage" :subtext="props.firstAverageBarSubtext" />
          <p class="average-bars-separator">m&aacute;s en detalle</p>
          <AverageBar :title="props.secondAverageBarTitle" :current="props.secondAverageBarCurrent"
            :average="props.secondAverageBarAverage" :subtext="props.secondAverageBarSubtext" />
          <AverageBar :title="props.thirdAverageBarTitle" :current="props.thirdAverageBarCurrent"
            :average="props.thirdAverageBarAverage" :subtext="props.thirdAverageBarSubtext" />
        </div>
      </div>
    </div>
    <SummaryResult :rating="props.summaryResultRating" />
  </div>
</template>

<script setup>
const props = defineProps({
  title: String,
  scoreBoxTitle: String,
  scoreBoxScore: Number,
  firstAverageBarTitle: String,
  firstAverageBarSubtext: String,
  firstAverageBarCurrent: Number,
  firstAverageBarAverage: Number,
  secondAverageBarTitle: String,
  secondAverageBarSubtext: String,
  secondAverageBarCurrent: Number,
  secondAverageBarAverage: Number,
  thirdAverageBarTitle: String,
  thirdAverageBarSubtext: String,
  thirdAverageBarCurrent: Number,
  thirdAverageBarAverage: Number,
  summaryResultRating: Number,
})
</script>

<style lang="scss" scoped>
.scores-summary {
  border: 1px solid var(--bluegray-100);
  border-radius: 0.5rem;

  .scores-summary-upper {
    padding: 1rem 1rem 2rem 1rem;

    small {
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: bold;
    }

    h5,
    button {
      font-size: 1rem;
    }
  }


  .scores-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1rem;

    .average-bars-separator {
      color: var(--bluegray-200);
      text-transform: uppercase;
      font-size: 0.6rem;
      margin: 0;
    }
  }

  @media (min-width: 768px) {
    .scores-summary-upper {
      h5 {
        font-size: 1.25rem;
      }
    }

    .scores-container {
      flex-direction: row;
      gap: 5rem;
    }
  }
}
</style>